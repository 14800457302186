import React from "react"
import { graphql } from 'gatsby'
// import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Wordmark from "../images/wordmark.svg"

export default (props) => (
  <Layout>
    <SEO title="Ruby on Rails and React Specialists | Belfast, Northern Ireland" />
    <Wordmark style={{ width: 400, maxWidth: "80%" }} />
  </Layout>
)

export const pageQuery = graphql`
  query {
    wordmark: file(relativePath: {eq: "wordmark.png"}) {
      childImageSharp {
        fixed(width: 190, height: 47, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`